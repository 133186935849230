var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { color: "primary", "slider-color": "orange", grow: "" } },
    [
      _c("v-tab", [_vm._v("\n    Timer fremover i tid\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("lesson-list", { key: "unpaid", attrs: { path: "future" } })],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Timer bakover i tid\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("lesson-list", { key: "recent", attrs: { path: "recent" } })],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Rapport senere enn 14 dager\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("lesson-list", {
                key: "lateReport",
                attrs: { path: "reportCreatedAfter/14" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Mangler innbetaling\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("lesson-list", { key: "unpaid", attrs: { path: "unpaid" } })],
            1
          )
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Mangler utbetaling\n  ")]),
      _c(
        "v-tab-item",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("lesson-list", {
                key: "untransferred",
                attrs: { path: "untransferred" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }